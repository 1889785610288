<template>
  <div
    class="SecretaryDetail"
    style="margin-right: 24px; flex: 1; overflow-x: hidden"
  >
    <div class="nav">
      首页 > <span class="click" @click="goBack">{{ this.$store.getters.info.fristName }}</span> > 详情
    </div>
    <div class="line" />

    <div class="rich2">
      <div class="title">
        <div class="left" />
        <div class="text">工作简介</div>
      </div>
      <div class="content rich" v-html="$setContent(info.rich)" />
    </div>

    <div class="line2" />

    <div class="rich2">
      <div class="title">
        <div class="left" />
        <div class="text">{{ this.$store.getters.info.fristName }}</div>
      </div>
      <div class="content2">
        <div
          v-for="(item, index) in info.list"
          :key="index"
          class="contentItem"
          style="padding-right: 24px"
        >
          <img class="img" :src="$beforeUrl(item.avatar)">
          <div class="name">{{ item.name }}</div>
          <div :class="{ hide: !item.honor }" class="youXiu center">
            {{ item.honor }}
            <div class="youXiuBg" />
          </div>
        </div>
      </div>
    </div>

    <div class="line3" />

    <div class="back center click" @click="goBack">返回栏目列表</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      info: {
        rich: this.$store.getters.info.fristNotes,
        list: []
      }
    }
  },

  mounted() {
    this.$parent.loading = false
  },
  created() {
    this.getData()
  },

  methods: {
    goBack() {
      this.$go('/Secretary')
    },
    getData() {
      this.$store.dispatch('main/SecretaryGetFirst').then((res) => {
        if (res.code === 0) {
          this.info.list = res.data
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.SecretaryDetail {
  .nav {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
  }
  .back {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    line-height: 32px;
    width: 120px;
    height: 40px;
    background: #ffffff;
    border: 1px solid #eeeeee;
    margin-top: 30px;
  }
  .line {
    height: 1px;
    background: #eeeeee;
    margin-top: 20px;
    margin-bottom: 30px;
  }
  .line2 {
    height: 1px;
    background: #eeeeee;
    margin-top: 31px;
    margin-bottom: 32px;
  }
  .line3 {
    height: 1px;
    background: #eeeeee;
    margin-top: 16px;
    margin-bottom: 25px;
  }
  .rich2 {
    width: 100%;

    .title {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      .left {
        width: 4px;
        height: 20px;
        background: var(--theme);
        margin-right: 7px;
      }
      .text {
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        line-height: 32px;
      }
    }
  }
  .content2 {
    display: flex;
    overflow-x: auto;
    width: 100%;
    .contentItem {
      box-sizing: content-box;
      height: 270px;
      width: 141px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 24px;
      .img {
        width: 141px;
        height: 197px!important;
      }
      .name {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
      }
      .youXiu {
        height: 24px;
        width: max-content;
        border: 1px solid var(--theme);
        border-radius: 4px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: var(--theme);
        position: relative;
        overflow: hidden;
        padding: 0 10px;
        .youXiuBg {
          background: var(--theme);
          opacity: 0.1;
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          top: 0;
          z-index: -1;
        }
      }
    }
  }
}
.hide {
  visibility: hidden;
}

::-webkit-scrollbar {
  width: 8px;
  height: 5px;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: rgba(0, 0, 0, 0.3);
  box-shadow: rgba(0, 0, 0, 0.3);
  border-radius: 10px;

}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(17, 16, 16, 0.13);
  -webkit-box-shadow: rgba(0, 0, 0, 0.9);
  box-shadow: rgba(0, 0, 0, 0.5);
}
::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(211, 173, 209, 0.4);
}
</style>
